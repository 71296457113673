<template>
  <div class="list-info">
    <top-bar :title="'房屋列表'" :left="true"></top-bar>

    <!-- 列表 -->
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
        v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ totalCount }}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="(item, index) in dataList" :key="index"
          @click="getInfo(item.id, item.migrantNum)">
          <div class="title">
            <div class="title-left">
              {{ (item.userName || '无房主') + ' | ' + (item.userMobile ? item.userMobileStr : '无联系方式') }}
            </div>
            <div class="title-right">
              {{ item.subareaName }}
            </div>
          </div>
          <div class="content">
            <img :src="item.url" class="content-img">
            <div class="content-text">
              <div class="top-text">{{ item.fullName }}
                <span v-for="item in item.labelList" :key="item" class="top-tag">{{ item }}</span>
              </div>
              <div class="bottom-text">居住人数<span style="color:#387FF5"> {{ item.liveNum }} </span>人 流动人口<span
                  style="color:#ffa93a"> {{ item.migrantNum }} </span>人
              </div>
            </div>
            <van-icon name="arrow" color="#666" class="bottom-icon" style="bottom: auto" />
          </div>
        </div>
      </van-list>
      <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { desensitization } from '@/utils/utils'
import { mapMutations } from 'vuex'

export default {
  name: 'house',
  components: {
    topBar
  },
  data() {
    return {
      page: 0,
      limit: 10,
      submitData: {
        gridId: '',
        subarea: '',
        buildingId: '',
        unitId: '',
        houseId: '',
        useType: '',
        labels: []
      },
      finished: false,
      loading: false,
      pullLoading: false,
      dataList: [],
      type: ''
    }
  },
  methods: {
    ...mapMutations,
    getInfo (id, migrantNum) {
      this.$store.commit("setHouseId", id);
      this.$store.commit("setMigrantNum", migrantNum)
      if (this.type != 3) {
        this.$router.push({
          path: "/family-info",
          query: { id },
        })
      } else {
        this.$router.push({
          path: "/isolation-info",
          query: { id },
        })
      }
    },
    getDataList () {
      this.page++
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/house/list"),
        method: "post",
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          userId: this.$userId,
          orgId: this.$orgId,
          loginUserId: this.$globalData.userInfo.userId,
          loginUserOrgId: this.$globalData.userInfo.orgId
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.clear()
          if (this.type == 2 && data.page.list.length == 1) {
            this.$store.commit("setHouseId", data.page.list[0].id)
            this.$store.commit("setMigrantNum", data.page.list[0].migrantNum)
            this.$router.replace({path: '/family-info', query: {id: data.page.list[0].id}})
          }
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          data.page.list.forEach((item) => {
            item.userMobileStr = desensitization(item.userMobile)
            let url = ""
            if (item.liveNum == 0) {
              url = require('@/assets/img/house-empty.png')
            } else if (item.useType == 2) {
              url = require('@/assets/img/house-rent.png')
            } else {
              url = require('@/assets/img/house-live.png')
            }
            item["url"] = url
          })
          this.totalCount = data.page.totalCount
          data.page.list.forEach((data) => {
            data["labelList"] = data.labelShorts ? data.labelShorts.split(",") : ""
          })
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false
        }
        this.$toast.clear()
      })
    },
    onPullDownRefresh(e) {
      this.finished = false;
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    onSearch() {
      this.page = 0;
      this.dataList = [];
      this.getDataList();
    },
    onCancel() {
      this.page = 0;
      this.dataList = [];
      this.searchValue = "";
      this.getDataList();
    }
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: "加载中...",
      forbidClick: true,
    })
    this.getDataList()
    this.type = this.$route.query.type
  }
}
</script>
